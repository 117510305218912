export const baseURL = "";

//
// DATA post get put delete (network)
//
export const DATA_POST_PERIZIA = "DATA_POST_PERIZIA";
// ------------------------------------------------------------------------------------------------------
export const DATA_ERROR = "DATA_ERROR";
export const DATA_FETCHING = "DATA_FETCHING";

// // ------------------------------------------------------------------------------------------------------
// //
// // AUTH post get put delete (network)
// //
// // ------------------------------------------------------------------------------------------------------

// export const LOGIN_REQUEST = "LOGIN_REQUEST";
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// export const LOGIN_FAILURE = "LOGIN_FAILURE";

// export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
// export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
// export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// export const DATI_REQUEST = "DATI_REQUEST";
// export const DATI_SUCCESS = "DATI_SUCCESS";
// export const DATI_FAILURE = "DATI_FAILURE";
